//
// Positions
//

.left-0 {
    left: 0 !important;
}

.right-0 {
    right: 0 !important;
}

.top-0 {
    top: 0 !important;
}
.top-50 {
    top: 50% !important;
}

.bottom-0 {
    bottom: 0 !important;
}

.zindex-0 {
    z-index: 0;
}

.zindex-n1 {
    z-index: -1;
}

.zindex-1 {
    z-index: 1;
}

.zindex-2 {
    z-index: 2;
}

.zindex-3 {
    z-index: 3;
}

.zindex-4 {
    z-index: 4;
}

.zindex-5 {
    z-index: 5;
}
